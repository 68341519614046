import React, { FC } from "react"
import { Link, graphql, PageProps } from "gatsby"
import * as R from "ramda"

import Layout from "../components/layout"
import { RemarkQueryType } from "../types"

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fields: { sourceName: { eq: "projects" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            projectType
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

type QueryProps = {
  data: {
    allMarkdownRemark: RemarkQueryType<
      {
        title: string
        projectType: string
      },
      {
        slug: string
      }
    >
  }
}

type Props = PageProps & QueryProps

const ProjectsPage: FC<Props> = ({ data }) => {
  return (
    <Layout>
      <h1>Projects</h1>
      <ProjectList title="Active Projects" data={data} projectType="active" />
      <ProjectList title="Games" data={data} projectType="game" />
      <ProjectList title="Student Projects at the HSZG" data={data} projectType="university" />
      <ProjectList title="Old Projects" data={data} projectType="old" />
      <ProjectList title="Unfinished Projects / Ideas" data={data} projectType="unfinished" />
    </Layout>
  )
}
export default ProjectsPage

const ProjectList: FC<{ data: QueryProps["data"]; projectType: string; title: string }> = ({
  data,
  projectType,
  title,
}) => {
  // group by project type. Creates an object like:
  // {
  //   "projectType": [{
  //     node: {
  //       ...
  //     }
  //   }],
  //  "otherProjectType": [...]
  // }
  const groupedByProjectType = R.groupBy((edge) => edge.node.frontmatter.projectType)(data.allMarkdownRemark.edges)

  const projects = groupedByProjectType[projectType]

  if (projects) {
    return (
      <>
        <h2>{title}</h2>
        <ul>
          {projects.map((element) => {
            const node = element.node
            return (
              <li key={node.id}>
                <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}
